<template>
  <div class='container'>
    <div class="tableBox">
      <h5>流量明细</h5>
      <div class="list">
        <table>
          <colgroup>
            <col style="width:25%">
            <col style="width:25%">
            <col style="width:25%">
            <col style="width:25%">
          </colgroup>
          <thead>
            <tr>
              <th>时间</th>
              <th>标题</th>
              <th>流量</th>
              <th>剩余流量</th>
            </tr>
          </thead>
        </table>
        <div class="pan-table__body" @scroll="eventScrooll($event)">
        <table>
          <colgroup>
            <col style="width:25%">
            <col style="width:25%">
            <col style="width:25%">
            <col style="width:25%">
          </colgroup>
          <tbody>
            <tr v-for="item in flowList" :key="item.id">
              <td>{{item.create_time}}</td>
              <td>{{item.title}}</td>
              <td>{{item.type == 1?'+':'-'}}{{formatSize(item.amount)}}</td>
              <td>{{formatSize(item.amount_over)}}</td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getFlowRecordList} from "@assets/api/flowDetail";

export default {
  name:'',
  components: {},

  data () {
    return {
      flowList:[],//流量列表
      pageIndex:1,
    };
  },

  computed: {},

  created(){
    this.getFlowList()
  },

  methods: {
    getFlowList(){
      let params = {
        page:this.pageIndex,
        limit:50,
      }
      getFlowRecordList(params).then(res=>{
        console.log('res',res)
        if(res.code == 1){
          let arr = res.data.list
          for(let item in arr){
            this.flowList.push(arr[item])
          }
        }else{
          this.$Message.error(`${res.msg}`)
        }
      })
    },
    //触底
    eventScrooll(e){
      if (Math.round(e.srcElement.scrollTop + 1 ) + e.srcElement.clientHeight >= e.srcElement.scrollHeight && e.srcElement.scrollHeight != 0) {
        this.pageIndex++
        // this.getshareRecordData()
        this.getFlowList()

      }
    },
  },

}

</script>
<style lang='less' scoped>
@import '@assets/css/profile/account/flowDetail.less';
</style>